import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Hard Drive (at least 3TB)`}</h2>
    <h2>{`Game Controller`}</h2>
    <p>{`Ideas:`}</p>
    <ol>
      <li parentName="ol">{`Xbox One controller`}</li>
    </ol>
    <h2>{`Brow pencil`}</h2>
    <h2>{`Office chair`}</h2>
    <h2>{`Library card`}</h2>
    <h2>{`Puffco`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      